<template>
  <div class="checkbox">
    <div class="chapterDetail_box" :name="index">
      <div class="chapterDetail_body">
        <div class="chapterDetail_title_card" :class="fontType">{{ item.subjectTitle }}</div>
        <div class="chapterDetail_body_box">
          <div
            class="chapterDetail_card"
            v-for="(res, itemIndex) in item.answerList"
            :name="index"
            @click="setAnswerItem(res,itemIndex)"
          >
            <div class="answer_item">
              <div
                class="answer_continer margin_v1"
                :style=" (answerRadioList.indexOf(item.answerList[itemIndex]) !=-1) ? 'background-color:#5F7DFF;' : ''"
              >
                <div class="answer_name" :class="fontType" :style=" (answerRadioList.indexOf(item.answerList[itemIndex]) !=-1) ? 'color:#FFFFFF;' : ''">{{ res.indexes }}</div>
              </div>
              <div class="answer_name margin_v row_width" :class="fontType">{{ res.answerName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  
  props: ['data','item','index','fontType'],
  data() {
    return {
        ids:-1,
        answerRadioList:[],
        
    };
  },
  name: "indeterCheckBox",

  methods: {
    setTitle(item){
        switch(item.classify){
            case "1":
                return "单项选择题";
                break;
            case "2":
                return "多项选择题";
                break;
            case "3":
                return "判断题";
                break;
            case "4":
                return "不定项选择题";
                break;
            case "5":
                return "计算分析题";
                break;
            case "6":
                return "综合题";
                break;
            case "7":
                return "简答题";
                break;
            case "8":
                return "计算回答题";
                break;
            case "9":
                return "案例分析题";
                break;
            case "10":
                return "计算题";
                break; 
            case "11":
                return "综合分析题";
                break;           
                
        }
    },
    setAnswerItem(val,itemIndex){
        val.listIndex = this.index;
        if(this.answerRadioList.indexOf(val) == -1){
          
          this.answerRadioList.push(val)
         
        }else {
          this.answerRadioList.splice(this.answerRadioList.indexOf(val),1)
         
        }

        this.$emit('answerCheckbox',this.answerRadioList,this.index);
    },    
    initAnsterLists(){
      const lists = this.item.answerList;
      const doneLists = this.item.doneAnswerIds;
      if(doneLists != null){
        let doneary = doneLists.split(",");
        let arys = [];
        doneary.map((item)=>{
          lists.map((resItem)=>{
            if(item == resItem.answerId){
              arys.push(resItem);
            }
          })
        })
        this.answerRadioList = arys;
      }
    }
  },
  created() {},
  mounted() {
    this.initAnsterLists();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.margin_v{
    margin-left: 12px;
}
.margin_v1{
    margin-left: 10px;
}

.chapterDetail_box{
    display: flex;
    width: 100%;
    height: calc(100vh - 46px);
    background: #ffffff;
    flex-direction: column;
    position: relative;
    .box_with{
        display: flex;
        width: 100%;
        height: 45px;
        background: #ffffff;
        .title_box{
            margin: 0px 14px;
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;
        }

    }
    .chapterDetail_body_box{
        width:100%;
        display: flex;
        flex-direction: column;
    }
    .chapterDetail_body{
        width: 100%;
        position: absolute;
        bottom: 0px;
        top: 0px;
        background-color: #f8f8f8;
        .chapterDetail_title_card{
            margin: 17px 14px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;


        }
        .chapterDetail_card{
            display: flex;
            flex-direction: column;
            margin: 0 14px 17px 14px;
            background: white;
            height: 55px;
            justify-content: center;
            
            .answer_item{
                display: flex;
                flex-direction: row;
                align-items: center;
                .answer_continer{
                    width: 24px;
                    height: 24px;
                    border: 1px solid #363636;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                }
                .answer_name{
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #141414;
                    align-items: center;
                    display: flex;

                    
                }
                .row_width{
                    width: calc(100% - 48px);
                }
            }
            

        }

    }
    .min{
      font-size: 12px !important;
    }
    .max{
      font-size: 18px !important;
    }
}
</style>
