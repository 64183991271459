<template>
  <div class="chapterDetail">
      <van-swipe class="my-swipe"  @change="curChange" ref="answerSwipe" :autoplay="0" :loop="false" :show-indicators="false" :touchable="true" indicator-color="white">
        <van-swipe-item v-for="(item, index) in AnswerList.paperSubjectList" :key="index">
            <radioCom   v-if="item.classify == '1' || item.classify == '3'" :data="AnswerList" :fontType="fontType" :isAnaly="isAnaly" @answerRadio="answerRadio" :item="item" :index="index" ></radioCom>
            <checkboxCom  v-if="item.classify == '2'" :data="AnswerList" :fontType="fontType" @answerCheckbox="answerCheckBox" :item="item" :index="index" ></checkboxCom>
            <indeterCom v-if="item.classify == '4' || item.classify == '10' || item.classify == '11'" :data="AnswerList" :fontType="fontType" @answerIndeter="answerIndeter" :item="item" :index="index"></indeterCom>
            <calCom v-if="item.classify == '5' 
            || item.classify == '6'
            || item.classify == '7'
            || item.classify == '8'
            || item.classify == '9'" 
            :data="AnswerList" :fontType="fontType" :item="item" @answerCal="answerCal"  :index="index"></calCom>
        </van-swipe-item>
      </van-swipe>
  </div>
</template>

<script>

import radioCom from '@/components/radio.vue'
import checkboxCom from '@/components/checkbox.vue'
import indeterCom from '@/components/indeterminate.vue';
import calCom from '@/components/calculation.vue';
export default {
    props: ['AnswerList','isNext','fontType','isAnaly'],
    components: {
        radioCom,
        checkboxCom,
        indeterCom,
        calCom,
        
    },
  data() {
      return {
        ids:-1,
        subMitList:[],
        curnet:0,
      }
    },
  name: 'chapterDetail',
  created(){
    
  },
  mounted(){
    
  },
 
  methods: {
    setSwiptTo(index){
        this.$refs.answerSwipe.swipeTo(index);
    },
    curChange(val){
        this.curnet = val;
    },
    getCurnet(){
        return this.curnet;
    },
    setIsShowJX(){
      this.isAnaly = 'yes';
    },
    onClickLeft() {
     this.$router.go(-1);
    },
    answerCal(res,index,itemIndex){
        this.subMitList[index] = res;
        this.$emit('allEventFn',{type:'cal'},res,itemIndex);
    },
    answerRadio(res){
        this.subMitList[res.listIndex] = res;
        this.isAnaly = 'no';
        this.$emit('allEventFn',{type:'radio'},res);
        if(this.isNext) {
          this.$refs.answerSwipe.next();
        }
        
    },
    
    answerCheckBox(res,index){
        this.subMitList[index] = res;
        this.$emit('allEventFn',{type:'checkbox'},res);
    },
    answerIndeter(res,index,itemIndex){
        this.subMitList[index] = res;
        this.$emit('allEventFn',{type:'indeter'},res,itemIndex);
    },
    setAnswerItem(val,itemIndex){
        this.ids = val.answerId;
    },
    setTitle(item){
        switch(item.classify){
            case "1":
                return "单项选择题";
                break;
            case "2":
                return "多项选择题";
                break;
            case "3":
                return "判断题";
                break;
            case "4":
                return "不定项选择题";
                break;
            case "5":
                return "计算分析题";
                break;
            case "6":
                return "综合题";
                break;
            case "7":
                return "简答题";
                break;
            case "8":
                return "计算回答题";
                break;
            case "9":
                return "案例分析题";
                break;
            case "10":
                return "计算题";
                break; 
            case "11":
                return "综合分析题";
                break;           
                
        }

    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less"  scoped>

 .chapterDetail{
    width: 100%;
    /* height: 100%; */
    background: #f5f5f5;
    
}

.margin_v{
    margin-left: 12px;
}
.margin_v1{
    margin-left: 10px;
}
.chapterDetail_box{
    display: flex;
    width: 100%;
    height: calc(100vh - 46px);
    background: #ffffff;
    flex-direction: column;
    position: relative;
    .box_with{
        display: flex;
        width: 100%;
        height: 45px;
        background: #ffffff;
        .title_box{
            margin: 0px 14px;
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;
        }

    }
    .chapterDetail_body_box{
        width:100%;
        display: flex;
        flex-direction: column;
    }
    .chapterDetail_body{
        width: 100%;
        position: absolute;
        bottom: 0px;
        top: 45px;
        background-color: #f8f8f8;
        .chapterDetail_title_card{
            margin: 17px 14px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;


        }
        .chapterDetail_card{
            display: flex;
            flex-direction: column;
            margin: 0 14px 17px 14px;
            background: white;
            height: 55px;
            justify-content: center;
            
            .answer_item{
                display: flex;
                flex-direction: row;
                align-items: center;
                .answer_continer{
                    width: 24px;
                    height: 24px;
                    border: 1px solid #363636;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .answer_name{
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #141414;
                }
            }

        }

    }
    
}
</style>
