<template>
  <div class="checkbox">
    <div class="chapterDetail_box" :name="index">
      <div class="box_with">
        <div class="title_box" :class="fontType">
          <div>{{ setTitle(item) }}</div>
          <div class="bj_sc_box">
            <img class="chapter_shoucang_box" @click="setIsSign(isSignSelect)" v-if="isSignSelect=='2'" src='@/assets/all_Icon/drawable-xxhdpi/question_untag.png' />
            <img class="chapter_shoucang_box" @click="setIsSign(isSignSelect)" v-if="isSignSelect=='1'"  src='@/assets/all_Icon/drawable-xxhdpi/question_tag.png' />
            <img class="chapter_shoucang_box" @click="setIsCollect(isCollectSelect)" v-if="isCollectSelect=='2'" src="@/assets/all_Icon/drawable-xxhdpi/question_collectioned.png" />
            <img class="chapter_shoucang_box" @click="setIsCollect(isCollectSelect)" v-if="isCollectSelect=='1'"  src="@/assets/all_Icon/drawable-xxhdpi/question_collectioned_jx.png" />
            <span>{{ index + 1 }}/{{ data.countNumber }}</span>
          </div>
        </div>
      </div>
      <div class="chapterDetail_body" v-if="isShowJX==='no'">
        <div class="chapterDetail_title_card" :class="fontType">{{ item.subjectTitle }}</div>
        <div class="chapterDetail_body_box">
          <div
            class="chapterDetail_card"
            v-for="(res, itemIndex) in item.answerList"
            :name="index"
            @click="setAnswerItem(res,itemIndex)"
          >
            <div class="answer_item">
              <div
                class="answer_continer margin_v1"
                :style=" (answerRadioList.indexOf(item.answerList[itemIndex]) !=-1) ? 'background-color:#5F7DFF;' : '' "
              >
                <div class="answer_name" :class="fontType" :style=" (answerRadioList.indexOf(item.answerList[itemIndex]) !=-1) ? 'color:#FFFFFF;' : '' ">{{ res.indexes }}</div>
              </div>
              <div class="answer_name margin_v row_width" :class="fontType">{{ res.answerName }}</div>
            </div>
          </div>
        </div>
      </div>
      <analysisCheckBoxCom v-else :subjectId="item.subjectId"></analysisCheckBoxCom>
    </div>
  </div>
</template>

<script>
import { add_sign,add_collect,deleted_collect} from "@/api/chapter.js";
import analysisCheckBoxCom from '@/components/analysis_com/analysis_checkbox.vue';
export default {
  props: ['data','item','index','fontType'],
  components:{
    analysisCheckBoxCom
  },
  data() {
    return {
        ids:-1,
        answerRadioList:[],
        isSignSelect:0,
        isCollectSelect:0,
        isShowJX:'no',
    };
  },
  computed:{
		Obj(){
			return this.$store.state.studyList.questionSwipeIndex.index;
		}
	},
  watch: {
        Obj(oldValue, newValue) {
          if(this.$store.getters.getResolution_method.key == 'header_res'){
            if(this.$store.getters.getQuestionSwipeIndex.index == this.index){
              this.isShowJX = 'yes';
            }else{
              this.isShowJX = 'no';

            }

          }
          
        }
  },
  name: "checkbox",
  methods: {
    setIsCollect(val){
      if(val==="2"){
        this.isCollectSelect = "1";
        let subjectId = this.item.subjectId;
        add_collect(subjectId).then((res)=>{});
      }else{
        this.isCollectSelect = "2";
        let subjectIds = this.item.subjectId;
        deleted_collect(subjectIds).then((res)=>{});
      }
    },
    setIsSign(val){
      if(val==="2"){
        this.isSignSelect = "1";
      }else{
        this.isSignSelect = "2";
      }
      let testPaperId = this.$route.query.id;
      let type = this.isSignSelect;
      let subjectId = this.item.subjectId;
      add_sign(subjectId,type,testPaperId).then((res)=>{})
               
    },
    setTitle(item){
        switch(item.classify){
            case "1":
                return "单项选择题";
                break;
            case "2":
                return "多项选择题";
                break;
            case "3":
                return "判断题";
                break;
            case "4":
                return "不定项选择题";
                break;
            case "5":
                return "计算分析题";
                break;
            case "6":
                return "综合题";
                break;
            case "7":
                return "简答题";
                break;
            case "8":
                return "计算回答题";
                break;
            case "9":
                return "案例分析题";
                break;
            case "10":
                return "计算题";
                break; 
            case "11":
                return "综合分析题";
                break;           
                
        }
    },
    setAnswerItem(val,itemIndex){
        val.listIndex = this.index;
        if(this.answerRadioList.indexOf(val) == -1){
          this.answerRadioList.push(val)
        }else {
          this.answerRadioList.splice(this.answerRadioList.indexOf(val),1)
        }
        this.$emit('answerCheckbox',this.answerRadioList,this.index);
    },    
    initAnsterLists(){
      const lists = this.item.answerList;
      const doneLists = this.item.doneAnswerIds;
      if(doneLists != null){
        let doneary = doneLists.split(",");
        let arys = [];
        doneary.map((item)=>{
          lists.map((resItem)=>{
            if(item == resItem.answerId){
              arys.push(resItem);
            }
          })
        })
        this.answerRadioList = arys;
      }
    }
  },

  created() {},
  mounted() {
    this.isSignSelect = this.item.isSign;
    this.isCollectSelect = this.item.isCollect;
    
    
    if(this.$store.getters.getResolution_method.key == 'header_res'){
      this.isShowJX = 'no';
    }else{
      this.isShowJX = 'yes';
    }
    this.initAnsterLists();

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.chapter_shoucang_box{
  width:18px;
  height: 16px;
  margin-right: 15px;
}

.bj_sc_box{
    display: flex;
    justify-content: space-between;
}
.margin_v{
    margin-left: 12px;
}
.margin_v1{
    margin-left: 10px;
}

.chapterDetail_box{
    display: flex;
    width: 100%;
    height: calc(100vh - 46px);
    background: #ffffff;
    flex-direction: column;
    position: relative;
    .box_with{
        display: flex;
        width: 100%;
        height: 45px;
        background: #ffffff;
        .title_box{
            margin: 0px 14px;
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;
        }

    }
    .chapterDetail_body_box{
        width:100%;
        display: flex;
        flex-direction: column;
    }
    .chapterDetail_body{
        width: 100%;
        position: absolute;
        bottom: 0px;
        top: 45px;
        background-color: #f8f8f8;
        .chapterDetail_title_card{
            margin: 17px 14px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;


        }
        .chapterDetail_card{
            display: flex;
            flex-direction: column;
            margin: 0 14px 17px 14px;
            background: white;
            height: 55px;
            justify-content: center;
            
            .answer_item{
                display: flex;
                flex-direction: row;
                align-items: center;
                .answer_continer{
                    width: 24px;
                    height: 24px;
                    border: 1px solid #363636;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .answer_name{
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #141414;
                    align-items: center;
                    display: flex;
                }
                .row_width{
                    width: calc(100% - 48px);
                }
            }
            

        }

    }
    .min{
      font-size: 12px !important;
    }
    .max{
      font-size: 18px !important;
    }
}
</style>
