<template>
    <div class="indeter">
        <div class="chapterDetail_box" :name="index">
            <div class="box_with">
                <div class="title_box" :class="fontType">
                    <div>{{ setTitle(item) }}</div>
                    <div class="bj_sc_box">
                        <img class="chapter_shoucang_box" @click="setIsSign(isSignSelect)" v-if="isSignSelect=='2'" src='@/assets/all_Icon/drawable-xxhdpi/question_untag.png' />
                        <img class="chapter_shoucang_box" @click="setIsSign(isSignSelect)" v-if="isSignSelect=='1'"  src='@/assets/all_Icon/drawable-xxhdpi/question_tag.png' />
                        <img class="chapter_shoucang_box" @click="setIsCollect(isCollectSelect)" v-if="isCollectSelect=='2'" src="@/assets/all_Icon/drawable-xxhdpi/question_collectioned.png" />
                        <img class="chapter_shoucang_box" @click="setIsCollect(isCollectSelect)" v-if="isCollectSelect=='1'"  src="@/assets/all_Icon/drawable-xxhdpi/question_collectioned_jx.png" />
                        <span>{{ index + 1 }}/{{ data.countNumber }}</span>
                    </div>
                </div>
            </div>
            <div class="chapterDetail_body">
                <div class="chapterDetail_title_card" v-html="item.subjectTitle" :class="fontType"></div>
                
            </div>
        </div>
        <div class="popup-box" :style="boxPositon=='up'?'bottom:-90px':'bottom:0px'">
            <div class="popup-box_up" @click="setPosition">
                <img class="chapter_cirle-logo"  src="@/assets/all_Icon/drawable-xxhdpi/zong_he_icon.png" />
            </div>
                <van-tabs v-model="active" :line-height="0">
                    <van-tab :title="setString(itemIndex)" :key="itemIndex" v-for="(res, itemIndex) in item.subjectList">
                        <indeterCheckBox v-if="isShowJX=='no'" @answerCheckbox="answerCheckBox" :fontType="fontType" :item="res" :index="itemIndex" ></indeterCheckBox>
                        <analysisCheckBoxCom v-else :subjectId="item.subjectId" :itemIndex="itemIndex"  :itemData="res"></analysisCheckBoxCom>
                    </van-tab>
                </van-tabs>
        </div>
    </div>
</template>
<script>
import indeterCheckBox from '@/components/indeterCheckBox.vue';
import analysisCheckBoxCom from '@/components/analysis_com/inder_jx_checkbox.vue';
import { add_sign,add_collect,deleted_collect} from "@/api/chapter.js";
export default {
        name: "indeter",
        props: ['data','item','index','fontType'],
        components: {
            indeterCheckBox,
            analysisCheckBoxCom
        },
        data() {
            return {
                boxPositon:"up",
                active:0,
                subIndeterList:[],
                isSignSelect:0,
                isCollectSelect:0,
                isShowJX:'no',

            }
        },
        computed:{
                Obj(){
                    return this.$store.state.studyList.questionSwipeIndex.index;
                }
            },
        watch: {
                Obj(oldValue, newValue) {
                    if(this.$store.getters.getResolution_method.key == 'header_res'){
                        if(this.$store.getters.getQuestionSwipeIndex.index == this.index){
                          this.isShowJX = 'yes';
                        }else{
                          this.isShowJX = 'no';
                        }

                    }
                }
        },
        mounted() {
            this.isSignSelect = this.item.isSign;
            this.isCollectSelect = this.item.isCollect;
            if(this.$store.getters.getResolution_method.key == 'header_res'){
              this.isShowJX = 'no';
            }else{
              this.isShowJX = 'yes';
            }
        },

        methods: {
            setIsCollect(val){
                if(val==="2"){
                    this.isCollectSelect = "1";
                    let subjectId = this.item.subjectId;
                    add_collect(subjectId).then((res)=>{});
                }else{
                    this.isCollectSelect = "2";
                    let subjectIds = this.item.subjectId;
                    deleted_collect(subjectIds).then((res)=>{});
                }
            },
            setIsSign(val){
                if(val==="2"){
                    this.isSignSelect = "1";
                }else{
                    this.isSignSelect = "2";
                }
                let testPaperId = this.$route.query.id;
                let type = this.isSignSelect;
                let subjectId = this.item.subjectId;
                add_sign(subjectId,type,testPaperId).then((res)=>{})
               
            },
            answerCheckBox(res,index){
                this.subIndeterList[index] = res;
                this.$emit('answerIndeter',this.subIndeterList,this.index,index);
            },
            setString(indexItem){
                let val = JSON.stringify(indexItem+1);
                return val;
            },
            setTitle(item){
                switch(item.classify){
                    case "1":
                        return "单项选择题";
                        break;
                    case "2":
                        return "多项选择题";
                        break;
                    case "3":
                        return "判断题";
                        break;
                    case "4":
                        return "不定项选择题";
                        break;
                    case "5":
                        return "计算分析题";
                        break;
                    case "6":
                        return "综合题";
                        break;
                    case "7":
                        return "简答题";
                        break;
                    case "8":
                        return "计算回答题";
                        break;
                    case "9":
                        return "案例分析题";
                        break;
                    case "10":
                        return "计算题";
                        break; 
                    case "11":
                        return "综合分析题";
                        break;           
                        
                }
            },
            setPosition(){
                if(this.boxPositon == "up"){
                    this.boxPositon = "down";
                }else{
                    this.boxPositon = "up";
                }

            }
            
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.chapter_cirle-logo{
  width:36px;
  height: 15px;
}
.chapter_shoucang_box{
  width:18px;
  height: 16px;
  margin-right: 15px;
}

.bj_sc_box{
    display: flex;
    justify-content: space-between;
}
.indeter{
    width: 100%;
    height: calc(100vh - 46px);
    position: relative;
    .popup-box{
        position: absolute;
        bottom: -350px;
        width: 100%;
        height: 535px;
        background-color:#ffffff;
        box-shadow: 2px 2px 50px #f8f8f8;
        .popup-box_up{
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
        }
        /deep/ .van-tab{
            flex:none;
            width: 25px;
            height: 25px;
            border: 1px solid #cdc5c5;
            margin-left: 14px;
        }
        /deep/ .van-tabs__nav--line{
            height: 20px;
            padding-bottom:0px
        }
        /deep/ .van-tabs__line{
            background-color: #ffffff;
        }
        /deep/ .van-tab--active{
            background-color:#5F7DFF;
            color: #ffffff;
            border: none;
        }
    }
}

.margin_v{
    margin-left: 12px;
}
.margin_v1{
    margin-left: 10px;
}

.chapterDetail_box{
    display: flex;
    width: 100%;
    height: calc(100vh - 500px);
    background: #ffffff;
    flex-direction: column;
    position: relative;
    .box_with{
        display: flex;
        width: 100%;
        height: 45px;
        background: #ffffff;
        .title_box{
            margin: 0px 14px;
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;
        }

    }
    .chapterDetail_body_box{
        width:100%;
        display: flex;
        flex-direction: column;
    }
    .chapterDetail_body{
        overflow: hidden;
        overflow-y: auto;
        width: 100%;
        position: absolute;
        bottom: 0px;
        top: 45px;
        background-color: #f8f8f8;
        .chapterDetail_title_card{
            margin: 17px 14px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;
            height: 360px;
            overflow: hidden;
            overflow-y: auto;


        }
        .chapterDetail_card{
            display: flex;
            flex-direction: column;
            margin: 0 14px 17px 14px;
            background: white;
            height: 55px;
            justify-content: center;
            
            .answer_item{
                display: flex;
                flex-direction: row;
                align-items: center;
                .answer_continer{
                    width: 24px;
                    height: 24px;
                    border: 1px solid #363636;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .answer_name{
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #141414;
                    line-height: 24px;
                }
            }
            

        }

    }
    .min{
      font-size: 12px !important;
    }
    .max{
      font-size: 18px !important;
    }
}
</style>
